<template>
  <v-data-table
    :headers='headers'
    :items='items'
    class='elevation-1'
    :footer-props='footerProps'
    item-key='Id'
    sort-by='Name'
    sort-desc.sync='true'
  >
    <template v-slot:item.actions="{ item }">
      <td style='text-align: center'>
        <button class='linkBtn' type='button' @click='viewDlg(item)'>
          View
        </button>
        <button
            class='linkBtn'
            type='button'
            v-show='allowEdit'
            @click='editDlg(item)'
        >
          Edit
        </button>
        <button
            class='linkBtn'
            type='button'
            v-show='allowDelete'
            @click='deleteDlg(item)'
        >
          Delete
        </button>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['items'],
  created() {
    this.allowEdit = this.$store.getters['UserStore/permissions'].includes(
      'dr.e'
    );
    this.allowDelete = this.$store.getters['UserStore/permissions'].includes(
      'dr.r'
    );
  },
  // computed: {
  //   items: {
  //     get() {
  //       return this.$store.getters['DrugsStore/Drugs'];
  //     }
  //   }
  // },
  data() {
    return {
      allowEdit: false,
      allowDelete: false,
      footerProps: {itemsPerPageOptions: [50, 100]},
      headers: [
        {
          text: 'Name',
          value: 'Name',
          sortable: true,
          width: '30%'
        },
        {
          text: 'Composition',
          value: 'Composition',
          sortable: true,
          width: '40%'
        },
        // {
        //   text: 'Dosage',
        //   value: 'Dosage',
        //   sortable: false,
        //   width: '40%'
        // },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '15%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    viewDlg(item) {
      this.$emit('view', item);
    },
    deleteDlg(item) {
      this.$emit('delete', item);
    },
    editDlg(item) {
      this.$emit('edit', item);
    }
  }
};
</script>

<style scoped></style>
