<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>{{ title }} medicine</div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.trim='dlgModel.Name'
          name='fName'
          label='Name:'
          :disabled='dlgType > 1'
          v-validate='{ required: true }'
          :error='!!errors.has("fName")'
          :error-messages='errors.first("fName")'
        ></v-text-field>
        <v-text-field
          v-model.trim='dlgModel.Composition'
          name='fComposition'
          label='Composition:'
          :disabled='dlgType > 1'
          v-validate='{ required: true }'
          :error='!!errors.has("fComposition")'
          :error-messages='errors.first("fComposition")'
        ></v-text-field>
        <v-autocomplete
          :disabled='dlgType > 1'
          label='Category:'
          :items='cats'
          item-text='Name'
          item-value='Id'
          v-model='dlgModel.CategoryId'
        >
        </v-autocomplete>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          :disabled='dlgType === 2'
          color='primary darken-1'
          text
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import drugsApi from '../../api/drugs';
export default {
  props: {
    dlgShow: {
      type: Boolean,
      default: false
    },
    dlgType: {
      type: Number,
      default: 2
    },
    item: Object
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dlgModel: {}
    };
  },
  computed: {
    cats() {
      return this.$store.getters['DrugsStore/Cats'];
    },
    title() {
      return this.dlgType === 0
        ? 'Create'
        : this.dlgType === 1
        ? 'Edit'
        : 'View';
    }
  },
  watch: {
    item: function() {
      if (this.dlgType === 0) {
        this.dlgModel = {
          Name: '',
          Composition: '',
          CategoryId: -1
        };
      } else {
        this.dlgModel = {
          Name: this.item.Name,
          Composition: this.item.Composition,
          CategoryId: this.item.CategoryId
        };
      }
    }
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {};
      this.$emit('close');
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      if (this.dlgType === 0) {
        this.api_create(this.dlgModel);
      } else {
        const newItem = {
          Id: this.item.Id,
          Name: this.dlgModel.Name,
          Composition: this.dlgModel.Composition,
          CategoryId: this.dlgModel.CategoryId
        };
        this.api_update(newItem);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await drugsApi.add(data);
      this.submit.working = false;
      if (res.Success) {
        this.$store.commit('DrugsStore/addDrug', res.Data);
        this.$notify({ type: 'info', text: 'Successfully added new medicine' });
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      this.submit.working = true;
      const res = await drugsApi.update(data);
      this.submit.working = false;
      if (res.Success) {
        this.$store.commit('DrugsStore/updateDrug', data);
        this.$notify({
          type: 'info',
          text: 'Successfully updated the medicine'
        });
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
