<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Medicines
        </div>
      </v-flex>
      <v-flex sm12 xs12 lg12>
        <v-btn color='primary' v-show='allowAdd' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New Medicine
        </v-btn>
      </v-flex>
      <v-flex sm3>
        <div class='filterStyle'>
          <v-autocomplete
            label='Category:'
            v-model='categoryId'
            :items='categories'
            item-text='Name'
            item-value='Id'
          >
            <template slot='prepend'>
              <v-icon class='filterIcon grey--text text--darken-1'
                >fa fa-filter</v-icon
              >
            </template>
          </v-autocomplete>
        </div>
      </v-flex>
      <v-flex sm12 xs12 lg12>
        <DrugsDisplay
          :items='drugs'
          @view='viewDlg'
          @edit='editDlg'
          @delete='deleteDlg'
        ></DrugsDisplay>
      </v-flex>
      <v-flex sm12 xs12 lg12>
        <DrugsDlg
          :dlg-show='dlgShow'
          :dlg-type='dlgType'
          :item='selectedItem'
          @close='closeDlg'
        >
        </DrugsDlg>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import drugsApi from '../../api/drugs';
import DrugsDisplay from '../../components/drugs/DrugsDisplay';
import DrugsDlg from '../../components/drugs/DrugsDlg';
export default {
  components: {
    DrugsDisplay,
    DrugsDlg
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
      'dr.a'
    );
    this.categories = Object.assign([], this.$store.getters['DrugsStore/Cats']);
    this.categories.splice(0, 0, { Id: 0, Name: 'All' });
    this.categoryId = 0;
  },
  computed: {
    drugs() {
      const items = this.$store.getters['DrugsStore/Drugs'];
      if (this.categoryId < 1) {
        return items;
      } else {
        return items.filter(x => x.CategoryId === this.categoryId);
      }
    }
  },
  data() {
    return {
      allowAdd: false,
      dlgShow: false,
      dlgType: 0,
      selectedItem: null,
      categories: [],
      categoryId: -1
    };
  },
  methods: {
    closeDlg() {
      this.selectedItem = null;
      this.dlgShow = false;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 2;
      this.dlgShow = true;
    },
    editDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    createDlg() {
      this.selectedItem = null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    async deleteDlg(item) {
      const res = await this.$notifyDlg.show(
        'Do you really want delete this medicine?',
        'Delete medicine',
        'warning',
        true
      );
      if (res) {
        this.api_delete(item);
      }
    },
    async api_delete(data) {
      const res = await drugsApi.delete(data.Id);
      if (res.Success) {
        this.$store.commit('DrugsStore/deleteDrug', data);
        this.$notify({
          type: 'info',
          text: 'Successfully deleted the medicine'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>
